import { createContext, useRef } from 'react';
import Logo from '@/components/assets/Logo';
import { useSwipeable } from 'react-swipeable';
import Toast, { ToastProps } from './Toast';
import QuestionCircle from './icons/QuestionCircle';
import Text from './Text';
import stopPropagation from '@/utils/stop-propagation';
import Modal, { ModalProps } from './Modal';
import { HELP_CENTER_LINK } from '@/utils/constants';
import Banner, { BannerProps } from './Banner';
import { useCreditCard } from '@/utils/use-credit-card';
import {
  showMaintenanceBanner,
  useMaintenanceBanner,
} from '@/utils/use-maintenance-banner';

interface PageLayoutProps {
  onClick?: () => void;
  onCloseModal?: () => void;
  nav: JSX.Element;
  children: JSX.Element | null;
  toast: ToastProps | null;
  modal: ModalProps | null;
  banner: BannerProps | null;
}

interface DesktopLayoutProps extends PageLayoutProps {
  TopRightMenu: () => JSX.Element | null;
}

const Hamburger = ({ onClick }: { onClick: (e: any) => unknown }) => {
  return (
    <div className="flex flex-col gap-y-1" onClick={onClick}>
      <div className="w-6 h-1 rounded bg-tertiary-5"></div>
      <div className="w-6 h-1 rounded bg-tertiary-5"></div>
      <div className="w-6 h-1 rounded bg-tertiary-5"></div>
    </div>
  );
};

export const TopNavContext = createContext<Element | null>(null);

const PageLayout = ({
  onClick,
  onCloseModal,
  TopRightMenu,
  nav,
  children,
  toast,
  modal,
  banner,
}: DesktopLayoutProps) => {
  const topNav = useRef(null);

  useCreditCard(true);
  useMaintenanceBanner(showMaintenanceBanner());

  return (
    <div onClick={onClick} className="relative">
      {banner && (
        <>
          <Banner {...banner}></Banner>
        </>
      )}
      <div
        className={`w-[calc(100%-250px)] min-h-[calc(100vh-80px)] pl-12 pr-12 pb-9 absolute left-[250px] top-[80px] flex flex-col justify-between gap-20 ${
          modal ? 'overflow-y-hidden h-[100%]' : null
        }
          ${banner ? 'pt-24' : 'pt-9'}`}
      >
        <div className="relative">
          <TopNavContext.Provider value={topNav.current}>
            {children}
          </TopNavContext.Provider>
        </div>
      </div>
      <div
        className="fixed flex justify-between items-center border-b-1 border-primary-0 h-20 w-full bg-tertiary-0 z-10"
        ref={topNav}
      >
        <div className="p-5 w-[250px] border-r-1 border-primary-0">
          <Logo />
        </div>
        <TopRightMenu />
      </div>
      <div className="fixed border-r-1 border-primary-0 h-screen w-[250px] pt-20 flex flex-col justify-between">
        {nav}

        <div className="p-5 align-center gap-4 flex flex-col">
          <a
            className="flex items-center gap-4 cursor-pointer no-underline"
            href={HELP_CENTER_LINK}
            target="_blank"
            rel="noopener"
          >
            <QuestionCircle />
            <Text.P>Help Center</Text.P>
          </a>
        </div>
      </div>
      {modal && (
        <div className="w-full fixed h-screen top-0 left-0 z-10 flex justify-center items-center">
          <div
            className="absolute w-full h-full top-0 left-0 bg-tertiary-7 bg-opacity-50 z-0"
            onClick={onCloseModal}
          >
            <div aria-hidden="true" className="hidden">
              Close Modal
            </div>
          </div>
          <div className="z-10">
            <Modal {...modal}></Modal>
          </div>
        </div>
      )}
      {toast && (
        <div className="w-full flex justify-center fixed top-20 z-20 mb-4">
          <Toast {...toast} />
        </div>
      )}
    </div>
  );
};

interface MobileLayoutProps extends PageLayoutProps {
  setNavOpen: (b: boolean) => unknown;
  navOpen?: boolean;
  Logout: () => JSX.Element | null;
}

PageLayout.Mobile = ({
  onClick,
  onCloseModal,
  children,
  setNavOpen,
  nav,
  navOpen,
  Logout,
  toast,
  modal,
  banner,
}: MobileLayoutProps) => {
  const topNav = useRef(null);

  useCreditCard(true);
  useMaintenanceBanner(showMaintenanceBanner());

  const handlers = useSwipeable({
    onSwipedLeft: () => setNavOpen(false),
    onSwipedRight: () => setNavOpen(true),
    delta: {
      left: 40,
      right: 40,
    },
  });

  return (
    <div className="h-full" {...handlers} onClick={onClick}>
      <div
        className={`h-[calc(100%-48px)] md:overflow-x-hidden relative top-12 ${
          modal ? 'overflow-y-hidden h-[calc(100vh-48px)]' : ''
        }`}
      >
        <div
          className={`h-full p-5 flex flex-col gap-y-4
        ${banner ? 'pt-[84px] p-5' : 'p-5'}`}
        >
          <TopNavContext.Provider value={topNav.current}>
            {children}
          </TopNavContext.Provider>
        </div>
      </div>
      <div
        className={`fixed h-[calc(100%-48px)] bg-tertiary-0 w-3/4 shadow-modal ${
          navOpen ? 'left-0' : '-left-[100%]'
        } ${
          banner ? 'top-28' : 'top-12'
        } transition-all duration-700 flex flex-col justify-between z-10`}
        onClick={stopPropagation()}
      >
        {nav}
        <div
          className={`w-full align-center gap-4 flex flex-col ${
            banner ? 'pb-[88px] p-6' : 'p-6'
          }`}
        >
          <a
            className="flex items-center gap-4 cursor-pointer"
            href={HELP_CENTER_LINK}
            target="_blank"
            rel="noopener"
          >
            <QuestionCircle className="h-6 w-6" />
            <Text.P>Help Center</Text.P>
          </a>
          <Logout />
        </div>
      </div>
      {banner && (
        <>
          <Banner {...banner}></Banner>
        </>
      )}
      <div
        className={`w-full h-12 border-b-1 border-primary-0 fixed top-0 bg-tertiary-0 z-10 ${
          banner ? 'mt-16' : ''
        }`}
        ref={topNav}
      >
        <div className="absolute t-0 w-full h-full flex justify-start items-center pl-4">
          <Hamburger onClick={stopPropagation(() => setNavOpen(!navOpen))} />
        </div>
        <div className="w-screen h-full flex justify-center items-center">
          <Logo />
        </div>
      </div>
      {modal && (
        <div className="w-full fixed h-screen top-0 left-0 z-10 flex justify-center items-center">
          <div
            className="absolute w-full h-full top-0 left-0 bg-tertiary-7 bg-opacity-50 z-0"
            onClick={onCloseModal}
          ></div>
          <div className="z-10">
            <Modal {...modal}></Modal>
          </div>
        </div>
      )}
      {toast && (
        <div className="w-screen flex justify-center fixed top-12 mb-4 z-20">
          <Toast {...toast} />
        </div>
      )}
    </div>
  );
};

export default PageLayout;
