import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import {
  therapyTypeToVisitType,
  errorSlotNotAvailable,
  toastSessionNotAvailable,
  toastDidntWorkTryAgain,
} from '@/utils/constants';
import {
  AnalyticsEvent,
  Trigger,
  JSONValue,
  JSONObject,
  ProviderProfileParams,
  TherapyType,
} from '@/utils/types';
import { transformPatientUuidToPatientRef } from '@/utils/appointments';
import RedirectToAppointmentsOnError from '@/pages/appointments/RedirectToAppointmentsOnError';
import Text from '@/components/Text';
import Dollar from '@/components/icons/Dollar';
import { getBookableSlotsForProvider } from '@/utils/appointments';
import Button from '@/components/Button';
import fetch from '@/utils/fetch';
import actions from '@/state/actions';
import { makeDollars } from '@/utils/format';
import { ToastProps } from '@/components/Toast';
import PaymentText from '@/components/appointments/PaymentText';
import trackEvent from '@/utils/amplitude';
import { getAppointmentEndTime } from '@/utils/dates';
import useRefreshCareTeam from '@/utils/use-refresh-care-team';
import ConfirmAppointmentCardHeader from '@/components/appointments/ConfirmAppointmentCardHeader';
import ConfirmAppointmentPageHeader from '@/components/appointments/ConfirmAppointmentPageHeader';
import TherapyTypeText from '@/components/appointments/TherapyTypeText';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { therapyTypeToAppointmentType } from '@/utils/constants';

const ChooseAppointmentWithoutPrevious = () => {
  const { providerNpi, therapyType, appointmentUnix } = useParams<
    ProviderProfileParams & {
      appointmentUnix: string;
    }
  >();
  const navigate = useNavigate();

  const { state, dispatch } = useContext(StateContext);
  const pageState = state.ui.page;
  const [waiting, setWaiting] = useState(false);
  const refreshCareTeam = useRefreshCareTeam(dispatch);

  const { WithData, data } = useData([
    'careTeam_v3',
    'insurance',
    'patientData',
    'selfPayRates',
  ]);

  if (pageState.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({ careTeam_v3: careTeam, insurance, patientData, selfPayRates }) => {
        const { payment_method, network_name, carrier } = insurance;
        const { patient_record_uuid, patient_uuid } = patientData;
        const appointmentUnixNum = Number(appointmentUnix);
        const { isAllowed } = useFlagCheck();
        const showBookAppointments = isAllowed({
          patientPortalReleaseBookFollowupAppointments: true,
        });

        const npi = Number(providerNpi);
        const provider = [...careTeam.active, ...careTeam.inactive].find(
          (p) => {
            return p?.npi === npi;
          }
        );
        if (!providerNpi || Number.isNaN(npi) || !provider) {
          console.error('invalid provider npi');
          return <RedirectToAppointmentsOnError />;
        }

        if (!showBookAppointments && therapyType !== 'psychiatric') {
          console.error('unsupported care type');
          return <RedirectToAppointmentsOnError />;
        }

        if (!therapyType) {
          console.error('please provide a therapy type');
          return <RedirectToAppointmentsOnError />;
        }
        const getButtonState = () => {
          if (waiting) {
            return 'waiting';
          }
          return '';
        };

        const { first_name, last_name, insurances } = provider;

        const providerName = `${first_name} ${last_name}`;

        if (!appointmentUnixNum || Number.isNaN(appointmentUnixNum)) {
          console.error('appointment slot missing or not provided');
          return <RedirectToAppointmentsOnError />;
        }

        const newAppointment = dayjs.unix(appointmentUnixNum);
        if (!newAppointment || newAppointment.isBefore(dayjs())) {
          console.error('appointment slot date is invalid');
          return <RedirectToAppointmentsOnError />;
        }

        const seriesType = 'followup';
        const isFirstAppointment = false;
        const bookableSlots = getBookableSlotsForProvider(
          state.ui.usedAvailabilitySlots,
          provider,
          therapyType,
          seriesType,
          false
        );

        if (!bookableSlots.includes(appointmentUnixNum)) {
          console.error('selected slot unavailable');
          return <RedirectToAppointmentsOnError />;
        }

        const appointmentType =
          therapyType && therapyTypeToVisitType[therapyType];

        const cashPayNumber =
          therapyType === 'psychiatric'
            ? selfPayRates.data.psych_initial
            : selfPayRates.data[therapyType];

        const cashPayAmount = makeDollars(cashPayNumber);

        const appointmentLengthInMinutes =
          therapyType === 'psychiatric' ? 30 : 60;
        const newEndTime = getAppointmentEndTime(
          appointmentUnixNum,
          appointmentLengthInMinutes
        );

        const trackBookNewFollowupEvent = (
          name: string,
          trigger: Trigger,
          metadata: { [key: string]: JSONValue } = {}
        ) => {
          const event: AnalyticsEvent = {
            product_area: 'Appointments',
            name,
            trigger,
            metadata: {
              appointment_type: appointmentType || null,
              appointment_duration_mins: appointmentLengthInMinutes,
              provider_name: providerName,
              provider_npi: providerNpi,
              treatment_type: therapyType,
              ...metadata,
            },
          };
          trackEvent(event);
        };

        useEffect(() => {
          trackBookNewFollowupEvent(
            'schedule_follow_up_confirm_page_view',
            'Page load'
          );
        }, []);

        const onSubmit = () => {
          const format = 'YYYY-MM-DD HH:mm:ssZ';
          const commonBody: JSONObject = {
            patient_record_uuid,
            provider_npi: providerNpi,
            therapy_type: therapyType,
            series_type: seriesType,
          };

          const v1body: JSONObject = {
            start_time: dayjs.unix(appointmentUnixNum).format(format),
            end_time: newEndTime.format(format),
            appointment_type:
              therapyTypeToAppointmentType[therapyType as TherapyType] || null,
            patient_ref: transformPatientUuidToPatientRef(patient_uuid),
          };

          const v2body: JSONObject = {
            start_time: dayjs.unix(appointmentUnixNum).toISOString(),
            end_time: newEndTime.toISOString(),
          };

          trackBookNewFollowupEvent(
            'schedule_follow_up_confirm_button_clicked',
            'Interaction'
          );
          setWaiting(true);

          let endpoint = '/api/create_appointment';
          let body = { ...commonBody, ...v1body };
          if (showBookAppointments) {
            endpoint = '/api/create_appointment_v2';
            body = { ...commonBody, ...v2body };
          }

          return fetch
            .json(endpoint, {
              method: 'POST',
              body,
            })
            .then(() => {
              trackBookNewFollowupEvent(
                'schedule_follow_up_successful',
                'Interaction',
                {
                  new_appointment_start_time: newAppointment.format(
                    'YYYY-MM-DDTHH:mm:ssZ'
                  ),
                }
              );
              setWaiting(false);
              refreshCareTeam();
              dispatch(
                actions.addUsedAvailabilitySlot({
                  npi,
                  slot: appointmentUnixNum,
                })
              );

              navigate('/appointments');
              dispatch(
                actions.setToast({
                  text: (
                    <Text.P.Inline>
                      <Text.P.Inline.Bold>Success! </Text.P.Inline.Bold>Your
                      appointment has been scheduled, and your provider has been
                      notified.
                    </Text.P.Inline>
                  ),
                  variant: 'success',
                  onClose: () => dispatch(actions.setToast(null)),
                })
              );
            })
            .catch((r) => {
              let errorMessage = <>{toastDidntWorkTryAgain}</>;
              const destinationRoute = `/appointments/schedule-follow-up/${providerNpi}/${therapyType}`;
              const variant: ToastProps['variant'] = 'warning';

              r.json().then(({ message }: { message: string }) => {
                if (message.includes(errorSlotNotAvailable)) {
                  errorMessage = <>{toastSessionNotAvailable}</>;
                  dispatch(
                    actions.addUsedAvailabilitySlot({
                      npi,
                      slot: appointmentUnixNum,
                    })
                  );
                }

                setWaiting(false);
                refreshCareTeam();
                navigate(destinationRoute);
                dispatch(
                  actions.setToast({
                    text: <Text.P>{errorMessage}</Text.P>,
                    variant: variant,
                    onClose: () => dispatch(actions.setToast(null)),
                  })
                );

                return Promise.reject();
              });
            });
        };

        return (
          <div className="space-y-10">
            <ConfirmAppointmentPageHeader />

            {/* Body */}
            <div className="max-w-[715px] mx-auto">
              <div className="border-1 border-tertiary-2 rounded-2 px-6 py-8 mb-6">
                <ConfirmAppointmentCardHeader
                  provider={provider}
                  newAppointment={newAppointment}
                  durationInMinutes={appointmentLengthInMinutes}
                />
                <div className="pt-4 justify-center items-center space-y-4">
                  <div className="flex gap-2">
                    <TherapyTypeText
                      isFirstAppointment={isFirstAppointment}
                      therapyType={therapyType}
                      appointmentType={appointmentType}
                    />
                  </div>
                  <div className="flex gap-2">
                    <Dollar className="grow-0 shrink-0" />
                    <PaymentText
                      paymentMethod={payment_method}
                      therapyType={therapyType}
                      cashPayAmount={cashPayAmount}
                      insurances={insurances}
                      networkName={network_name}
                      carrier={carrier}
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="primary"
                onClick={onSubmit}
                state={getButtonState()}
              >
                Book followup appointment
              </Button>
            </div>
          </div>
        );
      }}
    </WithData>
  );
};

export default ChooseAppointmentWithoutPrevious;
