import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import actions from '@/state/actions';
import Text from '@/components/Text';
import { useContext, useEffect } from 'react';
import { StateContext } from '@/App';
import useData from '@/state/use-data';
import EmptyStatePage from './EmptyStatePage';
import trackEvent from '@/utils/amplitude';
import {
  AnalyticsEvent,
  CareTeamV3,
  InsuranceDetails,
  TherapyType,
} from '@/utils/types';
import {
  therapyTypes as allTherapyTypes,
  kaiserHawaiiLink,
  kaiserSoCalLink,
  AGE_BANDS,
} from '@/utils/constants';
import { getAgeFromBirthdate } from '@/utils/dates';
import CareTeamTab from './care-team/CareTeamTab';
import Tab from '@/components/Tab';
import Button from '@/components/Button';
import Plus from '@/components/icons/Plus';
import InfoCard from '@/components/InfoCard';
import AddCareTypeModal from './care-team/AddCareTypeModal';
import { useFlagCheck } from '@/utils/use-feature-flags';
import SupportLink from '@/components/SupportLink';
import { isKaiserPayerExcluded } from '@/utils/tools';

type TabValue = 'care-team' | 'past-providers';
type AddableType = TherapyType | 'any';

const EmptyStateMessage = ({ activeTab }: { activeTab: TabValue }) => {
  let body: React.ReactNode;
  if (activeTab === 'care-team') {
    body = 'Click "Add care type" to add members to your care team';
  } else {
    body =
      'Members of your care team will show up here when you end care with them';
  }
  return <Text.P className="text-tertiary-7 max-w-[580px]">{body}</Text.P>;
};

const getPsychEligibility = (
  birthdate: string,
  state: string,
  psychStates: string[],
  insurance: InsuranceDetails
): boolean => {
  if (!psychStates.includes(state)) {
    return false;
  }

  if (isKaiserPayerExcluded(insurance)) {
    return false;
  }

  const age = getAgeFromBirthdate(birthdate);
  return (
    age >= AGE_BANDS.ADULT.MIN || (age >= AGE_BANDS.TEEN.MIN && state === 'CA')
  );
};

const CareTeamV2 = () => {
  const { dispatch } = useContext(StateContext);

  const { WithData, data } = useData([
    'careTeam_v3',
    'insurance',
    'patientData',
    'appointments_v2',
    'advancedMdOfficeKeyStateMap',
    'profile',
  ]);

  const params = new URLSearchParams(useLocation().search);

  // TODO...this is now abstracted into a util as it is used elsewhere. Remove and use that!
  const getCareTypes = (
    careTeam: CareTeamV3,
    hidePsych: boolean,
    birthdate: string
  ) => {
    const careTeamTypesSet = new Set<TherapyType>(
      careTeam.active
        .map(({ active_therapy_types }) =>
          active_therapy_types.map(({ therapy_type }) => therapy_type)
        )
        .flat()
    );

    let therapyTypes = allTherapyTypes;
    const age = getAgeFromBirthdate(birthdate);
    if (age < AGE_BANDS.ADULT.MIN) {
      therapyTypes = ['individual', 'psychiatric'];
    }
    const availableCareTypes = therapyTypes.filter(
      (therapyType) => !careTeamTypesSet.has(therapyType)
    );
    if (hidePsych && availableCareTypes.includes('psychiatric')) {
      availableCareTypes.splice(
        availableCareTypes.findIndex((type) => type === 'psychiatric'),
        1
      );
    }

    const careTeamTypesArray = Array.from(careTeamTypesSet);

    return { availableCareTypes, careTeamTypesArray };
  };

  return (
    <WithData data={data}>
      {({
        careTeam_v3: careTeam,
        patientData,
        advancedMdOfficeKeyStateMap: { data: stateKeys },
        profile: { birthdate },
        insurance,
      }) => {
        const { advanced_md_office_key: patientStateKey } = patientData;
        const { getFlag } = useFlagCheck();
        let statesWithPsych = getFlag(
          'ppbReleaseNewStatesForPsychSsEligibility'
        ) || ['CA'];
        statesWithPsych = statesWithPsych.concat(['MB']);

        const patientState = stateKeys[patientStateKey];

        const hidePsych = !getPsychEligibility(
          birthdate,
          patientState,
          statesWithPsych,
          insurance
        );
        const { availableCareTypes, careTeamTypesArray } = getCareTypes(
          careTeam,
          hidePsych,
          birthdate
        );

        const [activeTab, setActiveTab] = useState<TabValue>('care-team');
        const [addCareToolTipOpen, setAddCareToolTipOpen] = useState(false);

        useEffect(() => {
          const careTeamViewEvent: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'page_view',
            trigger: 'Page load',
            metadata: {
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          trackEvent(careTeamViewEvent);

          // deeplink launch add care type flow
          const careTypeToAdd = params.get('addCare') as AddableType;
          if (
            careTypeToAdd &&
            [...allTherapyTypes, 'any'].includes(careTypeToAdd)
          ) {
            const isUnallowedPsych =
              careTypeToAdd === 'psychiatric' && hidePsych;
            const alreadyActiveCareType = !availableCareTypes.includes(
              careTypeToAdd as TherapyType
            );
            const preSelected =
              isUnallowedPsych ||
              careTypeToAdd === 'any' ||
              alreadyActiveCareType
                ? undefined
                : careTypeToAdd;
            onAddCareTypeClick(preSelected);
          }
        }, []);

        const handleChangeTab = (value: TabValue) => {
          setActiveTab(value);
        };

        const onAddCareNextClick = (careType: TherapyType) => {
          const nextButtonClicked: AnalyticsEvent = {
            product_area: 'AddCareTeamModal',
            name: 'next_button_click',
            trigger: 'Interaction',
            metadata: {
              selected_care_type: careType,
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };

          trackEvent(nextButtonClicked);
        };

        const onAddCareTypeClick = (preSelected?: TherapyType) => {
          const providerRemoved = params.get('providerRemoved');

          dispatch(
            actions.setModal({
              children: (
                <AddCareTypeModal
                  existingCareTypes={careTeamTypesArray}
                  availableCareTypes={availableCareTypes}
                  preSelected={preSelected}
                  hidePsych={hidePsych}
                  providerPreviouslyRemoved={providerRemoved === 'true'}
                  trackNextClick={onAddCareNextClick}
                />
              ),
            })
          );

          const addCareModalViewed: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'add_care_modal_viewed',
            trigger: 'Interaction',
            metadata: {
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          if (preSelected) {
            addCareModalViewed.metadata = {
              ...addCareModalViewed.metadata,
              selected_care_types: [preSelected],
              is_deeplink: true,
            };
          }
          trackEvent(addCareModalViewed);
        };

        const onClickPastProviders = () => {
          setActiveTab('past-providers');
          firePastProvidersViewEvent();
        };

        const firePastProvidersViewEvent = () => {
          const viewPastProvidersEvent: AnalyticsEvent = {
            product_area: 'CareTeam',
            name: 'past_providers_view',
            trigger: 'Interaction',
            metadata: {
              existing_care_types: careTeamTypesArray,
              available_care_types: availableCareTypes,
            },
          };
          trackEvent(viewPastProvidersEvent);
        };

        const showActiveProviders = activeTab === 'care-team';
        const disableAddCareType =
          availableCareTypes.length === 0 ||
          (isKaiserPayerExcluded(insurance) &&
            careTeamTypesArray.includes('psychiatric'));
        const noCareTeam = !careTeam.active || careTeam.active.length === 0;

        return (
          <div className="max-w-[715px]">
            <div className="mb-7">
              <Text.H1 className="mb-2">Care Team</Text.H1>
            </div>
            <div className="flex flex-col mb-8 md:flex-row md:justify-between md:items-center">
              <div className="flex gap-x-5 mb-2 md:mb-0">
                <Tab
                  tab={{ name: 'Current providers', value: 'care-team' }}
                  onClick={handleChangeTab}
                  isActive={showActiveProviders}
                />
                <Tab
                  tab={{ name: 'Past providers', value: 'past-providers' }}
                  onClick={onClickPastProviders}
                  isActive={!showActiveProviders}
                />
              </div>

              <div
                className="relative"
                onMouseEnter={
                  disableAddCareType
                    ? () => setAddCareToolTipOpen(true)
                    : undefined
                }
                onMouseLeave={
                  disableAddCareType
                    ? () => setAddCareToolTipOpen(false)
                    : undefined
                }
              >
                <Button
                  variant="primary-outline"
                  state={disableAddCareType ? 'disabled' : ''}
                  width="auto"
                  size="small"
                  renderIcon={(props) => (
                    <Plus {...props} viewBox="0 0 16 16" />
                  )}
                  onClick={() => onAddCareTypeClick()}
                >
                  Add care type
                </Button>
                {addCareToolTipOpen ? (
                  <div className="absolute z-10 w-auto left-[0px] top-[45px] md:left-[-110px] md:w-[356px]">
                    {isKaiserPayerExcluded(insurance) &&
                    careTeamTypesArray.includes('psychiatric') ? (
                      <InfoCard>
                        You have already added psychiatry. To add therapy care
                        types, please contact Kaiser Permanente{' '}
                        <a
                          className="text-primary-3 no-underline cursor-pointer"
                          href={kaiserSoCalLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Southern California
                        </a>{' '}
                        or{' '}
                        <a
                          className="text-primary-3 no-underline cursor-pointer"
                          href={kaiserHawaiiLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hawaii
                        </a>{' '}
                        for a therapy referral to Rula. Once you have an active
                        referral, we will reach out to get you scheduled with a
                        therapist.
                      </InfoCard>
                    ) : (
                      <InfoCard>
                        You have already added all care types. Replace an
                        existing provider for the care type you’re looking to
                        change, or reach out to <SupportLink />
                      </InfoCard>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-between">
              {/* what is empty state if we have active/no past and vice versa */}
              {noCareTeam ? (
                <div className="space-y-4 basis-full">
                  <EmptyStatePage
                    header={
                      showActiveProviders
                        ? 'No current care team'
                        : 'No past providers'
                    }
                    message={<EmptyStateMessage activeTab={activeTab} />}
                  />
                </div>
              ) : (
                <CareTeamTab
                  providers={
                    showActiveProviders ? careTeam.active : careTeam.inactive
                  }
                  active={showActiveProviders}
                />
              )}
            </div>
          </div>
        );
      }}
    </WithData>
  );
};

export default CareTeamV2;
