import { useCallback, useContext, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MobileContext, StateContext } from '@/App';
import Dropdown from '@/components/Dropdown';
import Dollar from '@/components/icons/Dollar';
import RadioButton from '@/components/RadioButton';
import Text from '@/components/Text';
import TextInput from '@/components/TextInput';
import actions from '@/state/actions';
import {
  AuthenticatedState,
  CarrierOption,
  Data,
  ExtractEditing,
  InsuranceDetails,
  isEditing,
  JSONValue,
  PatientData,
  ProfilePageState,
} from '@/utils/types';
import useValidation, {
  makeErrorState,
  ValidationError,
} from '@/utils/use-validation';
import fetch from '@/utils/fetch';
import EditSection from './EditSection';
import ReadSection from './ReadSection';
import setDefaults from '@/utils/set-defaults';
import dayjs from 'dayjs';
import { toBase64 } from '@/utils/file';
import tryFunc from '@/utils/try-func';
import { parseMmDdYyyy } from '@/utils/dates';
import DateInput from '@/components/DateInput';
import InfoCircle from '@/components/icons/InfoCircle';
import InfoCard from '@/components/InfoCard';
import stopPropagation from '@/utils/stop-propagation';
import { usStateAbbreviations } from '@/utils/constants';
import trackEvent from '@/utils/amplitude';

export interface InsuranceProps {
  insurance: Extract<
    AuthenticatedState['data']['insurance'],
    { loadingState: 'done' }
  >;
  carriers: CarrierOption[];
  patientData: PatientData;
  pageState: ProfilePageState;
}

const Read = ({ payment_method, carrier }: InsuranceDetails) => {
  const { dispatch } = useContext(StateContext);

  return (
    <ReadSection
      label="Medical billing"
      onEditClick={() => dispatch(actions.profile.setInsuranceEditMode('edit'))}
    >
      <ReadSection.Row
        Icon={Dollar}
        data={
          payment_method === 'Health Insurance'
            ? `Insurance${carrier ? ` (${carrier})` : ''}`
            : 'Cash pay'
        }
      />
    </ReadSection>
  );
};

const Dropzone = ({
  onUpload,
}: {
  onUpload: (file: File | undefined) => unknown;
}) => {
  const [state, setState] = useState<
    { type: 'success' } | { type: 'error'; error: string } | { type: 'init' }
  >({ type: 'init' });
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const onError = (error: string) => {
    setState({
      type: 'error',
      error,
    });
    onUpload(undefined);
    setFilename(undefined);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length !== 1) {
      onError('Please upload a single file at a time');
      return;
    }
    if (
      ![
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/gif',
        'application/pdf',
      ].includes(acceptedFiles[0].type)
    ) {
      onError('Invalid file type (.png, .jpg, .jpeg, .gif, or .pdf required)');
      return;
    }
    if (acceptedFiles[0].size > 5000000) {
      onError('Please upload a file smaller than 5MB');
      return;
    }

    setState({ type: 'success' });
    const file = acceptedFiles[0];
    onUpload(file);
    setFilename(file.name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      className={`h-44 border-dashed rounded-2 border-1 flex justify-center items-center cursor-copy ${
        state.type === 'error'
          ? 'border-warning-1 bg-warning-0 text-warning-1'
          : state.type === 'success'
          ? 'border-secondary-3 bg-secondary-0 text-secondary-4'
          : 'border-primary-3 bg-primary-0 text-primary-4'
      }`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Text.P className="text-primary-4">
        {filename
          ? filename
          : state.type === 'error'
          ? state.error
          : 'Drag and drop or click to upload'}
      </Text.P>
    </div>
  );
};

const genderOptions: Array<{ key: string; value: 'Male' | 'Female' }> = (
  ['Male', 'Female'] as const
).map((g) => ({ key: g, value: g }));

const Edit = ({
  editState,
  carriers,
  patientData,
  pageState,
}: ExtractEditing<Data['insurance']> & {
  carriers: CarrierOption[];
  patientData: PatientData;
  pageState: ProfilePageState;
}) => {
  const { dispatch } = useContext(StateContext);
  const {
    payment_method,
    relationship_to_insured,
    subscriber,
    subscriber_id,
    carrier,
    front_of_card,
    back_of_card,
  } = editState.edited;

  const mobile = useContext(MobileContext);

  const validation = useValidation(() =>
    dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: false }))
  )(() => {
    const errors: ValidationError[] = [];
    if (!payment_method) {
      errors.push({
        keys: ['payment_method'],
        message: 'You must select a payment method',
      });
    }
    if (payment_method === 'Health Insurance') {
      if (!subscriber_id) {
        errors.push({
          keys: ['subscriber_id'],
          message: 'You must provide a subscriber ID',
        });
      }
      if (!carrier) {
        errors.push({
          keys: ['carrier'],
          message: 'You must select a carrier',
        });
      }
      if (!relationship_to_insured) {
        errors.push({
          keys: ['payment_method'],
          message: 'You must select your relationship to the insured',
        });
      }
      if (!front_of_card) {
        errors.push({
          keys: ['front_of_card'],
          message:
            'You must upload an image of the front of your insurance card',
        });
      }
      if (!back_of_card) {
        errors.push({
          keys: ['back_of_card'],
          message:
            'You must upload an image of the back of your insurance card',
        });
      }
      if (relationship_to_insured !== 'Self') {
        const missingFromSubscriber: string[] = (
          ['first_name', 'last_name', 'gender', 'birthdate'] as const
        ).filter((k) => !subscriber[k]);
        const missingFromSubscriberAddress: string[] = (
          ['street', 'city', 'state', 'zip'] as const
        ).filter((k) => !subscriber.address[k]);
        if (
          missingFromSubscriber.length ||
          missingFromSubscriberAddress.length
        ) {
          errors.push({
            keys: (missingFromSubscriber as string[]).concat(
              missingFromSubscriberAddress
            ),
            message:
              'You must provide the requested information for the insured',
          });
        }
        if (
          !subscriber['birthdate'] ||
          tryFunc(
            () =>
              !parseMmDdYyyy(subscriber['birthdate']).isBefore(
                dayjs().subtract(16, 'years')
              ),
            true
          )
        ) {
          errors.push({
            keys: ['birthdate'],
            message: 'You must provide a valid birth date in mm/dd/yyyy format',
          });
        }
      }
    }
    return errors;
  }, [
    payment_method,
    relationship_to_insured,
    subscriber,
    subscriber_id,
    carrier,
    front_of_card,
    back_of_card,
  ]);

  const frontOfCard = useMemo(() => {
    if (front_of_card) {
      return toBase64(front_of_card);
    } else {
      void Promise.resolve();
    }
  }, [front_of_card]);

  const backOfCard = useMemo(() => {
    if (back_of_card) {
      return toBase64(back_of_card);
    } else {
      void Promise.resolve();
    }
  }, [back_of_card]);

  const onSave = () => {
    () => dispatch(actions.setToast(null)); // clear any existing toasts
    if (validation.length) {
      dispatch(actions.profile.setInvalid({ key: 'insurance', invalid: true }));
      return false;
    }
    dispatch(actions.profile.setInsurancePersistence('saving'));
    return Promise.all([frontOfCard, backOfCard]).then(
      ([insurance_image_front_base64, insurance_image_back_base64]) => {
        const payload = setDefaults(
          {
            payment_method,
            insurance:
              payment_method === 'Health Insurance'
                ? {
                    carrier,
                    subscriber_id,
                    relationship_to_insured,
                    subscriber:
                      relationship_to_insured === 'Self'
                        ? null
                        : {
                            ...subscriber,
                            birthdate: parseMmDdYyyy(
                              subscriber.birthdate
                            ).toISOString(),
                          },
                  }
                : null,
          },
          ''
        ) as JSONValue;
        const files =
          front_of_card && back_of_card
            ? {
                insurance_image_front_base64: insurance_image_front_base64!,
                insurance_image_back_base64: insurance_image_back_base64!,
                insurance_image_front_original_filename: front_of_card.name,
                insurance_image_back_original_filename: back_of_card.name,
              }
            : null;
        return fetch
          .json('/api/update_insurance', {
            method: 'PUT',
            body: {
              patientIdentifiers: patientData,
              payload,
              files,
            },
          })
          .then((r) => {
            trackEvent({
              product_area: 'Profile',
              name: 'Payment_method_updated',
              trigger: 'Interaction',
              metadata: {
                payment_method,
              },
            });
            dispatch(
              actions.profile.setInsurancePersisted({
                network_name: r.payload.network_name,
              })
            );
          })
          .catch(() => {
            dispatch(
              actions.setToast({
                text: (
                  <Text.P>
                    We're sorry, but that didn't work. Please try again.
                  </Text.P>
                ),
                variant: 'warning',
                onClose: () => dispatch(actions.setToast(null)),
              })
            );
            dispatch(actions.profile.setInsurancePersistence('error'));
            return Promise.reject();
          });
      }
    );
  };
  const errorState = makeErrorState(!!editState.invalid, validation);

  return (
    <EditSection
      validationErrors={validation.map(({ message }) => message)}
      onSave={onSave}
      onCancel={() => dispatch(actions.profile.setInsuranceEditMode('read'))}
      label="Medical billing"
      invalid={!!editState.invalid}
      saving={editState.persistence === 'saving'}
    >
      <div>
        <Text.P className="mb-1">Select your preferred payment method</Text.P>
        <div className="flex flex-wrap gap-4">
          <RadioButton
            label="Insurance"
            name="paymentMethod"
            selectedValue={payment_method}
            value="Health Insurance"
            onCheck={() =>
              dispatch(
                actions.profile.setEditedPaymentMethod('Health Insurance')
              )
            }
          />
          <RadioButton
            label="Cash pay"
            name="paymentMethod"
            selectedValue={payment_method}
            value="Self Pay"
            onCheck={() =>
              dispatch(actions.profile.setEditedPaymentMethod('Self Pay'))
            }
          />
        </div>
      </div>
      {payment_method === 'Health Insurance' ? (
        <>
          <div>
            <Text.P className="mb-1">
              Who is the named person on the insurance policy?
            </Text.P>
            <div className="flex flex-wrap gap-4">
              <RadioButton
                label="Self"
                name="insured"
                selectedValue={relationship_to_insured ?? ''}
                value="Self"
                onCheck={() => {
                  dispatch(
                    actions.profile.setEditedInsurance({
                      relationship_to_insured: 'Self',
                    })
                  );
                }}
              />
              <RadioButton
                label="Spouse"
                name="insured"
                selectedValue={relationship_to_insured ?? ''}
                value="Spouse"
                onCheck={() => {
                  dispatch(
                    actions.profile.setEditedInsurance({
                      relationship_to_insured: 'Spouse',
                    })
                  );
                }}
              />
              <RadioButton
                label="Other"
                name="insured"
                selectedValue={relationship_to_insured ?? ''}
                value="Other"
                onCheck={() => {
                  dispatch(
                    actions.profile.setEditedInsurance({
                      relationship_to_insured: 'Other',
                    })
                  );
                }}
              />
              {relationship_to_insured && relationship_to_insured !== 'Self' && (
                <div className="border-t-1 border-b-1 border-tertiary-2 pt-4 pb-4 flex flex-wrap w-full gap-2">
                  <Text.P.Bold className="text-tertiary-6 basis-full space-y-1">
                    Responsible party's information
                  </Text.P.Bold>
                  <div className="basis-full space-y-1 md:basis-[calc(50%-4px)]">
                    <Text.P>First name</Text.P>
                    <TextInput
                      state={errorState('first_name')}
                      value={subscriber.first_name}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              first_name: v,
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(50%-4px)]">
                    <Text.P>Last name</Text.P>
                    <TextInput
                      state={errorState('last_name')}
                      value={subscriber.last_name}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              last_name: v,
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                    <Text.P>Date of birth</Text.P>
                    <DateInput
                      state={errorState('birthdate')}
                      value={subscriber.birthdate}
                      placeholder="MM/DD/YYYY"
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              birthdate: v,
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                    <div className="flex gap-1 items-center">
                      <Text.P>Sex at birth</Text.P>
                      <div className="w-5 h-5 cursor-pointer">
                        <InfoCircle.Small
                          onClick={stopPropagation(() =>
                            dispatch(
                              actions.profile.setSexAtBirthTooltipOpen(
                                !pageState.sexAtBirthTooltipOpen
                              )
                            )
                          )}
                          onMouseEnter={
                            mobile
                              ? undefined
                              : () =>
                                  dispatch(
                                    actions.profile.setSexAtBirthTooltipOpen(
                                      true
                                    )
                                  )
                          }
                        />
                      </div>
                    </div>
                    {pageState.sexAtBirthTooltipOpen && (
                      <div
                        onMouseLeave={
                          mobile
                            ? undefined
                            : () =>
                                dispatch(
                                  actions.profile.setSexAtBirthTooltipOpen(
                                    false
                                  )
                                )
                        }
                        className="flex-grow md:absolute md:left-[75px] md:w-max mb-2 md:mb-0 items-center"
                      >
                        <InfoCard>
                          We are required to collect this information for
                          insurance purposes. Please enter the sex at birth on
                          file with the insurer.
                        </InfoCard>
                      </div>
                    )}
                    <Dropdown
                      state={errorState('gender')}
                      options={genderOptions}
                      value={subscriber.gender}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              gender: v,
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                    <Text.P>Street address</Text.P>
                    <TextInput
                      state={errorState('street')}
                      value={subscriber.address.street}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              address: { ...subscriber.address, street: v },
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                    <Text.P>Apt #</Text.P>
                    <TextInput
                      value={subscriber.address.apt_suite}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              address: { ...subscriber.address, apt_suite: v },
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(66%-4px)]">
                    <Text.P>City</Text.P>
                    <TextInput
                      state={errorState('city')}
                      value={subscriber.address.city}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              address: { ...subscriber.address, city: v },
                            },
                          })
                        )
                      }
                    />
                  </div>
                  <div className="basis-full space-y-1 md:basis-[calc(33%-4px)]">
                    <Text.P>State</Text.P>
                    {/* height of this dropdown needs to subtract the Text.P height from the parent's height */}
                    <div className="h-[calc(100%-24px)]">
                      <Dropdown
                        state={errorState('state')}
                        value={subscriber.address.state}
                        onChange={(v) =>
                          dispatch(
                            actions.profile.setEditedInsurance({
                              subscriber: {
                                ...subscriber,
                                address: { ...subscriber.address, state: v },
                              },
                            })
                          )
                        }
                        options={usStateAbbreviations.map((v) => ({
                          key: v,
                          value: v,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="basis-full space-y-1">
                    <Text.P>Zip code</Text.P>
                    <TextInput
                      state={errorState('zip')}
                      value={subscriber.address.zip}
                      onChange={(v) =>
                        dispatch(
                          actions.profile.setEditedInsurance({
                            subscriber: {
                              ...subscriber,
                              address: { ...subscriber.address, zip: v },
                            },
                          })
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Text.P.Bold>Insurance details</Text.P.Bold>
          <div className="space-y-1">
            <Text.P>Insurance provider</Text.P>
            <Dropdown
              state={errorState('carrier')}
              options={carriers.map(({ label, value }) => ({
                key: label,
                value: value,
              }))}
              value={carrier}
              onChange={(v) =>
                dispatch(actions.profile.setEditedInsurance({ carrier: v }))
              }
            />
          </div>
          <div className="space-y-1">
            <Text.P>Subscriber ID / Member ID</Text.P>
            <TextInput
              state={errorState('subscriber_id')}
              value={subscriber_id}
              onChange={(v) =>
                dispatch(
                  actions.profile.setEditedInsurance({ subscriber_id: v })
                )
              }
            />
          </div>
          <div className="space-y-1">
            <Text.P>Upload a photo of the front of your insurance card</Text.P>
            <Dropzone
              onUpload={(f) =>
                dispatch(actions.profile.setInsuranceFrontOfCard(f))
              }
            />
          </div>
          <div className="space-y-1">
            <Text.P>Upload a photo of the back of your insurance card</Text.P>
            <Dropzone
              onUpload={(f) =>
                dispatch(actions.profile.setInsuranceBackOfCard(f))
              }
            />
          </div>
        </>
      ) : null}
    </EditSection>
  );
};

const Insurance = ({
  insurance,
  carriers,
  patientData,
  pageState,
}: InsuranceProps) => {
  return (
    <div>
      {dayjs().isBefore('2024-01-01') ? (
        <div className="flex gap-x-4 rounded-t items-center max-w-[572px] p-6 bg-tertiary-4 text-tertiary-0">
          <div>
            <InfoCircle size={24} stroke="tertiary-0" />
          </div>
          <p>
            Updating your insurance for 2024? Please wait until January 1st,
            2024 to add your new plan to get the most accurate benefits
            information.
          </p>
        </div>
      ) : null}
      {isEditing(insurance) ? (
        <Edit
          {...insurance}
          carriers={carriers}
          patientData={patientData}
          pageState={pageState}
        />
      ) : (
        <Read {...insurance} />
      )}
    </div>
  );
};

export default Insurance;
