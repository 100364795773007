import { useState, useContext } from 'react';
import Button from '@/components/Button';
import Ellipsis from '@/components/icons/Ellipsis';
import ReplaceProviderButtonV2 from './ReplaceProviderButtonV2';
import { TherapyType } from '@/utils/types';
import { useNavigate } from 'react-router-dom';
import { MobileContext } from '@/App';
import Text from '@/components/Text';
import trackEvent from '@/utils/amplitude';
import { useFlagCheck } from '@/utils/use-feature-flags';

interface Props {
  npi: number;
  therapyType: TherapyType;
  canBook?: boolean;
}

const ProviderCardDropdown = ({ npi, therapyType, canBook = false }: Props) => {
  const mobile = useContext(MobileContext);
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { isAllowed } = useFlagCheck();
  const showBookAppointments = isAllowed({
    patientPortalReleaseBookFollowupAppointments: true,
  });

  const handleBookClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    trackEvent({
      product_area: 'CareTeam',
      name: 'book_appointment_clicked',
      trigger: 'Interaction',
    });
    navigate(`/appointments/schedule-follow-up/${npi}/${therapyType}`);
  };
  return (
    <div
      className="relative"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setMobileMenuOpen(!mobileMenuOpen);
      }}
    >
      <Button size="small" data-testid="overflow-menu">
        <Ellipsis />
      </Button>
      {mobileMenuOpen ? (
        <div
          className={`absolute flex flex-col cursor-pointer gap-6 p-4 top-11 w-[188px] bg-tertiary-0 z-10 rounded-2 shadow-hover right-0 ${
            mobile ? 'right-0' : 'left-0'
          }`}
        >
          {showBookAppointments && canBook ? (
            <Text.P
              role="button"
              onClick={(e: React.MouseEvent) => handleBookClick(e)}
            >
              Book appointment
            </Text.P>
          ) : null}
          <ReplaceProviderButtonV2
            providerNpi={npi}
            therapyType={therapyType}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ProviderCardDropdown;
