import { MobileContext } from '@/App';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigation from './BackNavigation';
import Text from '@/components/Text';
import { useCreditCard } from '@/utils/use-credit-card';

const FullWidthHeader = ({
  children,
  returnPath = '/care-team',
  returnPage = 'care team',
}: {
  children?: React.ReactNode;
  returnPath?: string;
  returnPage?: string;
}) => {
  const baseHeight = 160;
  const mobileTopPadding = 24;
  const desktopTopPadding = 36;
  const mobile = useContext(MobileContext);
  const [height, setHeight] = useState(
    baseHeight - (mobile ? mobileTopPadding : desktopTopPadding)
  );
  const ref = useRef<HTMLDivElement>(null);
  const { creditCardIsValid, hideCreditCardBanner } = useCreditCard(false);

  useEffect(() => {
    if (ref.current) {
      setHeight(
        ref.current.clientHeight -
          (mobile ? mobileTopPadding : desktopTopPadding)
      );
    }
  });

  return (
    <div>
      <div
        className={`
        bg-quaternary-0 w-full min-h-[160px] fixed left-0 px-5 py-10 z-10
        sm:left-[15.625rem] sm:px-12 sm:w-[calc(100%-250px)] ${
          !creditCardIsValid && !hideCreditCardBanner
            ? 'top-[9rem]'
            : 'top-12 sm:top-[80px]'
        }`}
        ref={ref}
      >
        <div className="flex justify-between items-center">
          <BackNavigation />
          <div>
            <Link
              to={returnPath}
              className="no-underline hover:text-primary-3 w-fit"
            >
              <Text.P className="inline-block">Return to {returnPage}</Text.P>
            </Link>
          </div>
        </div>
        <div className="w-full mt-5 text-tertiary-7">
          {mobile ? (
            <Text.H3>{children}</Text.H3>
          ) : (
            <Text.H2>{children}</Text.H2>
          )}
        </div>
      </div>
      {/* spacing to account for fixed header div */}
      <div style={{ height: `${height}px` }}></div>
    </div>
  );
};

export default FullWidthHeader;
