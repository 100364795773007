import { PatientData, ProfileDetails, ServerData } from '@/utils/types';
import {
  useFlags,
  useLDClient,
  ProviderConfig,
} from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import config from './config';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

export const ldInitConfig: ProviderConfig = {
  clientSideID: config.VITE_LD_CLIENT_ID,
  // initialize with anonymous context
  // https://docs.launchdarkly.com/sdk/features/anonymous#javascript
  context: {
    kind: 'user',
    anonymous: true,
  },
  options: {
    // turning off diagnostics to attempt to address network errors
    // https://github.com/launchdarkly/react-client-sdk/issues/106#issuecomment-1632848830
    diagnosticOptOut: true,
    sendEvents: false,
    application: {
      id: 'patient_portal',
    },
  },
};

const LAUNCH_DARKLY_TIMEOUT = 3000;
// can use other contexts here, e.g. location context
type UserContext = {
  kind: 'user';
  key: string;
  isPathEmployee: boolean;
  officeKey: number;
};

export type FeatureFlags = {
  patientPortalHideCcStatusBanner?: boolean;
  enablePatientPsychAppointmentManagement?: boolean;
  ppbReleaseNewStatesForPsychSsEligibility?: string[];
  patientPortalShowMaintenanceBanner?: boolean;
  releaseSelfServeMessaging?: boolean;
  patientPortalReleaseBookFollowupAppointments?: boolean;
};

export const useIdentifyUser = (
  patientData: ServerData<PatientData>,
  profile: ServerData<ProfileDetails>
) => {
  const ldClient = useLDClient();
  const [identified, setIdentified] = useState(false);

  useEffect(() => {
    if (
      ldClient &&
      profile.loadingState === 'done' &&
      patientData.loadingState === 'done'
    ) {
      if (!profile.email || !patientData.patient_record_uuid) {
        return;
      }
      const userContext: UserContext = {
        kind: 'user',
        key: patientData.patient_record_uuid,
        isPathEmployee: profile.email.toLowerCase().includes('@pathccm.com'),
        officeKey: patientData.advanced_md_office_key,
      };

      // timeout in case LD takes too long
      const ldTimeout = setTimeout(() => {
        console.error('LD timeout');
        setIdentified(true);
      }, LAUNCH_DARKLY_TIMEOUT);

      void ldClient.identify(userContext).finally(() => {
        setIdentified(true);
        clearTimeout(ldTimeout);
      });
    }
  }, [ldClient, patientData, profile]);

  return identified;
};

export const isAllowed = (
  requiredFlags: FeatureFlags | undefined,
  flags: LDFlagSet
) => {
  if (!requiredFlags) {
    // if no required flags, let it through
    return true;
  }
  let k: keyof typeof requiredFlags;
  for (k in requiredFlags) {
    if (flags[k] !== requiredFlags[k]) {
      return false;
    }
  }
  return true;
};

/**
 * custom hook that returns an `isAllowed` function that accepts required flags
 *
 * example usage:
 *
 * const MyComponent = () => {
 *  const { isAllowed } = useFlagCheck();
 *  return isAllowed({ myFlag: true }) ? <ComponentA /> : <ComponentB />;
 * }
 */
export const useFlagCheck = () => {
  const flags = useFlags();
  return {
    isAllowed: (requiredFlags: FeatureFlags | undefined) => {
      return isAllowed(requiredFlags, flags);
    },
    getFlag: (flag: keyof FeatureFlags) => flags[flag] || undefined,
  };
};
