import {
  supportEmail,
  HELP_CENTER_LINK,
  getAmdPatientPortalLink,
  AGE_BANDS,
} from './constants';
import {
  WindowStorage,
  Loaded,
  ProviderRole,
  ActiveProviderShapeV3,
  InactiveProviderShape,
  CareTeamV3,
  ActiveTherapyType,
  InsuranceDetails,
} from './types';
import {
  InfoCardProps,
  Participant,
  MessageThread,
} from '@pathccm/path-components-messaging';
import dayjs from 'dayjs';
import { getAgeFromBirthdate } from './dates';

export const storageAvailable = (type: WindowStorage) => {
  try {
    const storage = window[type],
      test = 'storageTest';
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const getInfoCardProps = ({
  provider,
  isPsych = false,
  officeKey,
}: {
  provider: ActiveProviderShapeV3 | InactiveProviderShape;
  isPsych: boolean;
  officeKey: number;
}): InfoCardProps => {
  const {
    first_name: firstName,
    approved_client_email: email,
    approved_client_facing_email: showEmail,
  } = provider;
  const cardInfoList = [
    {
      sectionTitle: 'General support',
      sectionLinks: [
        {
          text: 'Help center',
          url: HELP_CENTER_LINK,
          openInNewWindow: true,
        },
      ],
    },
    {
      sectionTitle: 'Crisis',
      sectionLinks: [
        {
          text: 'Emergency resources',
          url: 'https://patientsupport.rula.com/hc/en-us/articles/22411553547931-Resources-for-patients-in-crisis',
          openInNewWindow: true,
        },
      ],
    },
  ];

  let contact;
  let contactAddress;
  let cardSubtext;
  let openInNewWindow = false;

  if (isPsych && provider.two_letter_state === 'CA') {
    // Psych W2
    contact = 'med@rula.com';
    cardSubtext = `${firstName} works with a specialized team to efficiently coordinate your care.`;
    contactAddress = 'mailto:med@rula.com';
  } else if (isPsych) {
    // Psych 1099
    contact = 'AdvancedMD';
    cardSubtext = `${firstName} receives messages via AdvancedMD`;
    contactAddress = getAmdPatientPortalLink(officeKey);
    openInNewWindow = true;
  } else {
    // therapists who have not signed up on provider portal
    const emailToUse = showEmail ? email : supportEmail;
    contact = emailToUse;
    cardSubtext = `${firstName} uses Rula to manage their front office`;
    contactAddress = `mailto:${emailToUse}`;
  }

  if (contact && contactAddress) {
    cardInfoList.unshift({
      sectionTitle: 'Contact',
      sectionLinks: [{ text: contact, url: contactAddress, openInNewWindow }],
    });
  }

  return {
    cardSubtext,
    cardInfoList,
  };
};

type ProviderParticipant = Participant & {
  earliestActiveAppointmentStart: string | null;
};

const getLastSent = (
  participant: ProviderParticipant,
  threads: MessageThread[]
) => {
  const thread = threads.find(
    (t) => t.providerUuid === participant.uuid
  ) as MessageThread & { lastSent: string };
  // have to update this type
  return thread?.lastSent || '';
};

const getSortedByLastMessage = (
  participants: ProviderParticipant[],
  threads: MessageThread[]
) => {
  const sorted = [...participants].sort((a, b) => {
    const lastSentA = getLastSent(a, threads);
    const lastSentB = getLastSent(b, threads);

    if (lastSentA && lastSentB) {
      return dayjs(lastSentB).isBefore(dayjs(lastSentA)) ? -1 : 1;
    }

    if (!lastSentA && !lastSentB) {
      return a.firstName.localeCompare(b.firstName, undefined, {
        sensitivity: 'base',
      });
    }

    return lastSentA ? -1 : 1;
  });
  return sorted;
};

export const mapProviderInfo = (
  providerList: (ActiveProviderShapeV3 | InactiveProviderShape)[],
  threads: MessageThread[]
): ProviderParticipant[] =>
  getSortedByLastMessage(
    providerList.map(
      ({
        first_name,
        last_name,
        uuid,
        profile_image_s3_url,
        role,
        ...rest
      }: ActiveProviderShapeV3 | InactiveProviderShape) => {
        let earliestActiveAppointmentStart = null;
        if ('active_therapy_types' in rest) {
          const {
            active_therapy_types,
          }: { active_therapy_types: ActiveTherapyType[] } = rest;
          const activeTherapyTypesLength = active_therapy_types.length;
          // earliestActiveAppointmentStart is currently only leveraged for an amplitude event
          // metric (message_before_initial_appointment) in messaging
          // If there is only one active_therapy_type, we can easily grab that one
          // In the context of messaging, we do not necessarily need to know which therapy type,
          // just the earliest appointment start time amidst all therapy types
          if (activeTherapyTypesLength === 1) {
            earliestActiveAppointmentStart =
              active_therapy_types[0].earliest_active_start_time || null;
          } else if (activeTherapyTypesLength > 1) {
            earliestActiveAppointmentStart =
              [...active_therapy_types].sort((a, b) => {
                const aDate = a.earliest_active_start_time || '';
                const bDate = b.earliest_active_start_time || '';

                // earliest_active_start_time is ISO 8601 format (2024-09-05T12:00:00.000Z) and can be
                // be lexicographically compared via localeCompare
                return aDate.localeCompare(bDate);
              })[0]?.earliest_active_start_time || null;
          }
        }

        return {
          firstName: first_name,
          lastName: last_name,
          uuid,
          subtitle: getMessagingParticipantRole(role),
          image: profile_image_s3_url,
          earliestActiveAppointmentStart,
        };
      }
    ),
    threads
  );

export const getProviderFromCareTeam = (
  careTeam: Loaded<CareTeamV3>,
  participantUuid: string
): ActiveProviderShapeV3 | InactiveProviderShape | undefined => {
  const completeCareTeam = [...careTeam.active, ...careTeam.inactive];

  return completeCareTeam.find(({ uuid }) => uuid === participantUuid);
};

export const getMessagingParticipantRole = (
  providerRole: ProviderRole | undefined
) => {
  if (providerRole) {
    return providerRole === 'PRESCRIBER'
      ? 'Psychiatric Practitioner'
      : 'Therapist';
  }

  return 'Provider';
};

export const cannotMessageReason = (
  careTeam: Loaded<CareTeamV3>,
  participantUuid: string
) => {
  const provider = getProviderFromCareTeam(careTeam, participantUuid);
  if (provider?.role === 'PRESCRIBER') {
    if (provider.two_letter_state === 'CA') {
      return 'psychiatry w2';
    }

    return 'psychiatry 1099';
  }

  if (!provider?.portal_account_active) {
    return 'no portal account';
  }
  return '';
};

export const isKaiserPayerExcluded = (insurance: InsuranceDetails): boolean => {
  // Hard code in payer exclusions since we want to always exclude
  // Kaiser from adding care for now
  const payerExclusions = [
    'Kaiser Permanente (Southern CA)',
    'Kaiser Permanente (Hawaii)',
    'Kaiser Permanente (Northern CA)',
    'Kaiser Foundation Health Plan',
    'Kaiser Permanente',
  ];

  return (
    insurance.payment_method === 'Health Insurance' &&
    (payerExclusions.includes(insurance.carrier ?? '') ||
      payerExclusions.includes(insurance.network_name ?? ''))
  );
};

export const getPsychEligibility = (
  birthdate: string,
  state: string,
  psychStates: string[],
  insurance: InsuranceDetails
): boolean => {
  if (!psychStates.includes(state) || isKaiserPayerExcluded(insurance)) {
    return false;
  }

  const age = getAgeFromBirthdate(birthdate);
  return (
    age >= AGE_BANDS.ADULT.MIN || (age >= AGE_BANDS.TEEN.MIN && state === 'CA')
  );
};
