import { CareTeamV3, TherapyType, InsuranceDetails } from '@/utils/types';
import { providerLicenses } from '@/utils/constants';
import Text from './Text';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import X from './icons/X';
import useSortedProvidersAndHeadings from '@/utils/use-sort-providers-and-headings';
import { therapyTypeToVisitType } from '@/utils/constants';
import { getCareTypes } from '@/utils/provider';
import { useContext } from 'react';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import Button from './Button';
import SupportLink from './SupportLink';
import AddCareTypeModal from '@/pages/care-team/AddCareTypeModal';
import { isKaiserPayerExcluded } from '@/utils/tools';
import { useNavigate } from 'react-router-dom';
import trackEvent from '@/utils/amplitude';

interface BookingModalProps {
  careTeam: CareTeamV3;
  patientStateKey: number;
  birthdate: string;
  insurance: InsuranceDetails;
  stateKeys: Record<number, string>;
}
const BookingModal = ({
  careTeam,
  patientStateKey,
  birthdate,
  insurance,
  stateKeys,
}: BookingModalProps) => {
  const { dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  const { providersAndHeadings } = useSortedProvidersAndHeadings(
    careTeam.active,
    true
  );
  const { availableCareTypes, careTeamTypesArray, hidePsych } = getCareTypes({
    careTeam,
    stateKeys,
    insurance,
    birthdate,
    patientStateKey,
  });

  const noProviders = careTeam.active.length === 0;
  const onClose = () => {
    dispatch(actions.setModal(null));
  };

  const onBookClick = (therapyType: TherapyType, npi: number) => {
    trackEvent({
      product_area: 'BookingModal',
      name: 'book_clicked',
      trigger: 'Interaction',
      metadata: {
        therapy_type: therapyType,
        provider_npi: npi,
      },
    });
    navigate(`/appointments/schedule-follow-up/${npi}/${therapyType}`);
  };

  const onAddProvider = () => {
    dispatch(
      actions.setModal({
        children: (
          <AddCareTypeModal
            existingCareTypes={careTeamTypesArray}
            availableCareTypes={availableCareTypes}
            hidePsych={hidePsych}
            trackNextClick={() => {}}
          />
        ),
      })
    );
  };

  const disableAddCareType =
    availableCareTypes.length === 0 ||
    (isKaiserPayerExcluded(insurance) &&
      careTeamTypesArray.includes('psychiatric'));

  return (
    <div
      data-testid="booking-modal"
      className="h-screen w-screen overflow-auto rounded-2 p-5 bg-tertiary-0 md:h-auto md:w-[610px] md:max-h-[500px] md:p-6 scrollbar-hide"
    >
      <div className="flex flex-col justify-between align-top space-y-5">
        <div className="flex justify-between items-center">
          <Text.H4>Choose a provider to book with</Text.H4>
          <X role="img" onClick={onClose} className="cursor-pointer"></X>
        </div>
        <div className="flex justify-between items-center">
          <Text.P.Bold className="text-tertiary-6">
            Current Providers
          </Text.P.Bold>
          {disableAddCareType ? null : (
            <Text.P.Bold
              onClick={onAddProvider}
              className="text-primary-3 cursor-pointer"
              role="button"
            >
              + Add provider
            </Text.P.Bold>
          )}
        </div>
        {noProviders ? (
          <div className="text-center pb-[44px]">
            <Text.H4 className="mb-2">No current providers</Text.H4>
            <Text.P>
              Add a provider or contact <SupportLink />
            </Text.P>
          </div>
        ) : (
          <>
            <div className="space-y-4 basis-full w-full">
              {providersAndHeadings
                .sort((a, b) =>
                  a.heading.localeCompare(b.heading, undefined, {
                    sensitivity: 'base',
                  })
                )
                .map(({ heading, providers }) => {
                  return (
                    <div key={heading}>
                      <div className="bg-gray-100 mb-6 px-3 py-1 rounded-3">
                        <Text.Small.Bold>
                          {therapyTypeToVisitType[heading]}
                        </Text.Small.Bold>
                      </div>
                      <div>
                        {providers.map((provider) => {
                          const providerLicense =
                            providerLicenses[provider.licenses[0]]?.name || '';
                          const cannotBook =
                            'is_confirmed' in provider &&
                            !provider.is_confirmed;
                          return (
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (cannotBook) return;
                                onBookClick(heading, provider.npi);
                              }}
                              className="m-auto mb-6 md:min-w-[485px]"
                              role={cannotBook ? undefined : 'button'}
                            >
                              <div className="flex items-center gap-x-4 flex-row gap-1">
                                <ProviderHeadshot
                                  profileImage={provider.profile_image_s3_url}
                                  className="w-10 h-10"
                                />
                                <div
                                  className={`flex items-center flex-row w-full md:justify-between md:h-auto`}
                                >
                                  <div className="flex flex-col md:items-start justify-center overflow-hidden pr-3">
                                    <Text.P className="overflow-hidden text-ellipsis line-clamp-1">
                                      {provider.first_name} {provider.last_name}
                                    </Text.P>
                                    {providerLicense && (
                                      <Text.Small className="text-ellipsis text-grey-500 overflow-hidden whitespace-nowrap md:whitespace-normal md:mx-[unset]">
                                        {providerLicense}
                                      </Text.Small>
                                    )}
                                  </div>
                                  <div className="hidden items-center md:flex">
                                    <Button
                                      state={
                                        cannotBook ? 'disabled' : undefined
                                      }
                                      variant="primary-outline"
                                      size="small"
                                      width="w-[90px]"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (cannotBook) return;
                                        onBookClick(heading, provider.npi);
                                      }}
                                    >
                                      Book
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {noProviders ? null : (
        <Text.Small className="text-tertiary-5">
          For additional assistance contact <SupportLink />
        </Text.Small>
      )}
    </div>
  );
};

export default BookingModal;
