import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import fetch from '@/utils/fetch';
import Button from './Button';
import Text from './Text';
import PopupMenu from './PopupMenu';
import Pill from './Pills/Pill';
import { StateContext } from '@/App';
import actions from '@/state/actions';
import {
  AnalyticsEvent,
  Trigger,
  TherapyType,
  SeriesType,
} from '@/utils/types';
import CancelModal from '@/pages/appointments/CancelModal';
import {
  getAppointmentLengthAndEndTime,
  getAppointmentTimeInfo,
} from '@/utils/dates';
import RescheduleFeeWarningModal from '@/pages/appointments/reschedule/RescheduleFeeWarningModal';
import trackEvent from '@/utils/amplitude';
import { useFlagCheck } from '../utils/use-feature-flags';
import CheckInBanner from './CheckInBanner';

export interface AppointmentCardProps {
  providerName: string;
  isFirstAppointment?: boolean;
  appointmentType?: string;
  appointmentUUID: string;
  patientRecordUUID: string;
  handleCopyZoomLink: (inviteUrl: string) => void;
  zoomMeetingId?: string;
  isHold: boolean;
  providerNpi: number;
  providerEmail?: string;
  cancellationFee?: string;
  patientRef: string;
  providerRef: string;
  startTime: string;
  endTime: string;
  joinUrl?: string;
  seriesType?: SeriesType;
  therapyType?: TherapyType;
  checkedIn: boolean;
}

const AppointmentCard = ({
  appointmentType,
  appointmentUUID,
  patientRecordUUID,
  providerName,
  isFirstAppointment,
  joinUrl,
  handleCopyZoomLink,
  isHold,
  cancellationFee,
  providerNpi,
  patientRef,
  providerRef,
  startTime,
  endTime,
  seriesType,
  therapyType,
  checkedIn,
}: AppointmentCardProps) => {
  const { dispatch } = useContext(StateContext);
  const [searchParams] = useSearchParams();
  const onZoomLinkClick = () => {
    if (joinUrl) {
      trackEvent({
        product_area: 'Appointments',
        name: 'copy_link_clicked',
        trigger: 'Interaction',
      });
      handleCopyZoomLink(joinUrl);
    }
  };
  const navigate = useNavigate();
  const {
    appointmentDayMonthDate,
    appointmentDayAbbr,
    appointmentDate,
    appointmentStartTime,
    appointmentTimeZone,
    appointmentEndTime,
  } = getAppointmentTimeInfo(startTime, endTime);
  const appointmentTime = `${appointmentStartTime} - ${appointmentEndTime} (${appointmentTimeZone})`;

  const { appointmentLengthInMinutes } = getAppointmentLengthAndEndTime(
    startTime,
    endTime
  );

  const { isAllowed } = useFlagCheck();
  const isLate = !!cancellationFee;
  const allowPsychFollowupReschedule = isLate
    ? isAllowed({ enablePatientPsychAppointmentManagement: true })
    : true;

  const allowReschedule =
    seriesType !== 'followup' ||
    (seriesType === 'followup' &&
      (therapyType !== 'psychiatric' || allowPsychFollowupReschedule));

  useEffect(() => {
    handleDeeplinks();
  }, []);

  const handleDeeplinks = () => {
    const isDeeplink = !!searchParams.get('deeplink');
    const deeplinkAction = searchParams.get('action');
    const deeplinkAppointmentUUID = searchParams.get('appointmentUUID');
    if (
      isDeeplink &&
      deeplinkAction === 'cancel' &&
      deeplinkAppointmentUUID === appointmentUUID
    ) {
      trackCancelOrRescheduleInteractions(
        'cancel_appointment_modal_viewed',
        'Deeplink'
      );
      showCancelModal();
    }
  };

  const cancelAppointment = () => {
    return fetch
      .json('/api/cancel_appointment', {
        method: 'PUT',
        body: {
          patient_record_uuid: patientRecordUUID,
          appointment_uuid: appointmentUUID,
          status: isHold ? 'deleted' : 'canceled',
          cancel_subsequent: !!isFirstAppointment,
          cancellation_metadata: {
            provider_ref: providerRef,
            appointment_date_time: dayjs(startTime).toISOString(),
            patient_ref: patientRef,
          },
        },
      })
      .then(() =>
        dispatch(
          actions.async.setLoading({
            key: 'appointments_v2',
            loadingState: 'needed',
          })
        )
      )
      .catch(() => Promise.reject());
  };

  const trackCancelOrRescheduleInteractions = (
    name: string,
    trigger: Trigger = 'Interaction'
  ) => {
    const metadata = {
      appointment_id: appointmentUUID,
      appointment_type: appointmentType || null,
      appointment_status: isHold ? 'hold' : 'appointment',
      appointment_start_time: startTime,
      appointment_duration_mins: appointmentLengthInMinutes,
      provider_name: providerName,
      provider_npi: providerNpi,
      treatment_type:
        appointmentType === 'Psychiatry' ? 'psychiatry' : 'therapy',
      within_late_cancel_window: !!cancellationFee,
    };
    const event: AnalyticsEvent = {
      product_area: 'Appointments',
      name,
      trigger,
      metadata,
    };
    trackEvent(event);
  };

  const showCancelModal = () => {
    dispatch(
      actions.setModal({
        children: (
          <CancelModal
            dispatch={dispatch}
            cancellationFee={cancellationFee}
            isFirstAppointment={isFirstAppointment}
            cancelAppointment={cancelAppointment}
            trackEvent={trackCancelOrRescheduleInteractions}
            providerName={providerName}
            appointmentTime={appointmentTime}
            appointmentDayMonthDate={appointmentDayMonthDate}
          />
        ),
      })
    );
  };
  const onCancelClick = () => {
    trackCancelOrRescheduleInteractions(
      'cancel_appointment_entry_point_clicked'
    );
    showCancelModal();
  };

  const onRescheduleClick = () => {
    trackCancelOrRescheduleInteractions(
      'reschedule_appointments_entry_point_clicked'
    );
    const rescheduleURL = `/appointments/reschedule/${providerNpi}/${appointmentUUID}`;
    if (cancellationFee) {
      trackCancelOrRescheduleInteractions(
        'last_minute_reschedule_modal_viewed'
      );
      dispatch(
        actions.setModal({
          children: (
            <RescheduleFeeWarningModal
              dispatch={dispatch}
              cancellationFee={cancellationFee}
              rescheduleURL={rescheduleURL}
              trackEvent={trackCancelOrRescheduleInteractions}
            />
          ),
        })
      );
    } else {
      navigate(rescheduleURL);
      return;
    }
  };

  const enableZoomButton =
    joinUrl && dayjs(startTime).diff(dayjs(), 'minute') <= 30;

  const showCheckInBanner = dayjs(startTime).diff(dayjs(), 'hour') <= 48;

  const getPopupMenu = (classes: string) => (
    <div className={classes}>
      <PopupMenu verticalPosition="top-6" width="w-[220px]">
        <Text.P role="button" onClick={onCancelClick} className="p-2">
          Cancel appointment
        </Text.P>
        {allowReschedule ? (
          <Text.P role="button" onClick={onRescheduleClick} className="p-2">
            Reschedule appointment
          </Text.P>
        ) : (
          <></>
        )}
        {joinUrl ? (
          <Text.P role="button" onClick={onZoomLinkClick} className="p-2">
            Copy appointment link
          </Text.P>
        ) : (
          <></>
        )}
      </PopupMenu>
    </div>
  );

  return (
    <div className="m-auto flex flex-col border-tertiary-2 rounded-2 border-1 max-w-[350px] md:max-w-[714px] md:m-0">
      <div
        data-testid="appointment-card"
        className="flex flex-col items-center text-tertiary-7 p-9 md:py-7 md:px-0 md:items-left md:flex-row"
      >
        <div className="w-full flex flex-col rounded-t-2 items-center relative md:justify-center md:rounded-none md:h-auto md:w-[106px]">
          <Text.XSmall.Bold>{appointmentDayAbbr}</Text.XSmall.Bold>
          <Text.H1 className="text-[30px]">{appointmentDate}</Text.H1>
          {getPopupMenu('absolute top-0 right-0 md:hidden')}
        </div>

        {/* vertical line */}
        <div className="w-[3px] h-[72px] py-1 bg-primary-3 rounded-3 hidden md:block" />
        {/* horizontal line */}
        <div className="w-[65px] h-[3px] bg-primary-3 my-3 rounded-3 block md:hidden" />
        <div className="flex flex-col flex-grow items-center text-tertiary-5 gap-y-[2px] md:py-1 md:pl-6 md:items-start">
          <div className="flex flex-col items-center gap-x-1 md:flex-row">
            <Text.H3>{appointmentType || 'Therapy'}</Text.H3>
            {isFirstAppointment ? (
              <Pill size="small">First appointment</Pill>
            ) : null}
          </div>
          <Text.Small className="text-tertiary-4">{appointmentTime}</Text.Small>
          <Text.Small className="text-tertiary-4">{providerName}</Text.Small>
        </div>

        <div className="w-full h-[1px] bg-tertiary-2 my-7 block md:hidden" />
        <div className="w-full flex flex-col items-center md:gap-x-4 md:flex-row md:px-9 md:w-auto">
          <Button
            width="w-full md:w-[94px]"
            state={!enableZoomButton ? 'disabled' : undefined}
            variant="primary"
            size="medium"
            sizeClasses="w-full"
            onClick={() => {
              trackEvent({
                product_area: 'Appointments',
                name: 'start_appointment_clicked',
                trigger: 'Interaction',
              });
            }}
          >
            <a href={joinUrl} target="_blank" className="no-underline">
              Join
            </a>
          </Button>
          {getPopupMenu(
            'hidden justify-between gap-5 items-center w-full md:flex'
          )}
        </div>
      </div>
      {showCheckInBanner ? (
        <CheckInBanner
          extraStyles="rounded-b-2"
          checkedIn={checkedIn}
          appointmentUuid={appointmentUUID}
          appointmentTime={startTime}
          providerNpi={providerNpi}
        />
      ) : null}
    </div>
  );
};

export default AppointmentCard;
